import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { Helmet } from "react-helmet";
import { useClearCache } from "react-clear-cache";
import PrivateRoute from "./services/privateRoute.js";
import { createBrowserHistory as createHistory } from "history";
import Signup from "./pages/Signup/Signup";
import Login from "./pages/Login/Login";
import Login2FA from "./pages/Login2FA/Login2FA.js";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Changelog from "./pages/Changelog/Changelog.js";
// context import
import { LoginProvider } from "./contexts/LoginContext.js";
// google analytics
import ReactGA from "react-ga";
import UAcode from "./services/analyticsUACode";
// sentry import
import * as Sentry from "@sentry/react";
// posthog import
import posthog from "posthog-js";
// styles import
import "./app.scss";
// utils
import { loadScriptWithPriority } from "./utilities/utils";

const App = () => {
  if (
    process.env.REACT_APP_ENV_NAME === "staging" ||
    process.env.REACT_APP_ENV_NAME === "production"
  )
    // Configuration and initialization posthog
    posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
      api_host: process.env.REACT_APP_POSTHOG_API_HOST,
      autocapture: false,
      capture_pageleave: false,
      capture_pageview: false,
    });
  // else if (process.env.REACT_APP_ENV_NAME === "production") {
  //   // Configuration and initialization posthog
  //   posthog.init(process.env.REACT_APP_POSTHOG_KEY, {
  //     api_host: "https://metrics.decentro.tech",
  //     autocapture: false,
  //   });
  // }
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  //Google Analytics Code intialization
  if (window.location.host === "dashboard.decentro.tech") {
    ReactGA.initialize(UAcode);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  // clear cache based on version
  const clearCache = (event) => {
    event.preventDefault();
    emptyCacheStorage();
  };
  const history = createHistory();

  const lightSchemeIcon = document.querySelector("link#light-scheme-icon");
  const darkSchemeIcon = document.querySelector("link#dark-scheme-icon");

  const isDark = window.matchMedia("(prefers-color-scheme: dark)");

  const changeFavicon = () => {
    if (isDark.matches) {
      document.head.append(darkSchemeIcon);
      lightSchemeIcon.remove();
    } else {
      darkSchemeIcon.remove();
      document.head.append(lightSchemeIcon);
    }
  };

  React.useEffect(() => {
    changeFavicon();
    var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
    if (isSafari) {
      isDark.addListener(changeFavicon);
    } else {
      isDark.addEventListener("change", changeFavicon);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  React.useEffect(() => {
    // Initialize non-critical scripts
    const initNonCriticalScripts = () => {

      // Hotjar
      loadScriptWithPriority(
        `(function(h,o,t,j,a,r){
          h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
          h._hjSettings={hjid:5259932,hjsv:6};
          a=o.getElementsByTagName('head')[0];
          r=o.createElement('script');r.async=1;
          r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
          a.appendChild(r);
      })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`,
        "low"
      );
    };

    // Load scripts based on user interaction
    const handleUserInteraction = () => {
      initNonCriticalScripts();
      window.removeEventListener("scroll", handleUserInteraction);
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
    };

    // Add event listeners for non-critical scripts
    window.addEventListener("scroll", handleUserInteraction, { passive: true });
    window.addEventListener("mousemove", handleUserInteraction, {
      passive: true,
    });
    window.addEventListener("touchstart", handleUserInteraction, {
      passive: true,
    });

    return () => {
      window.removeEventListener("scroll", handleUserInteraction);
      window.removeEventListener("mousemove", handleUserInteraction);
      window.removeEventListener("touchstart", handleUserInteraction);
    };
  }, []);

  return (
    <React.Fragment>
      <Helmet>
        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=G-L69LEPJR4P"
        ></script>
        <script>
          {`window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'G-L69LEPJR4P');`}
        </script>
      </Helmet>
      <div className="app-main">
        <div className="app-content">
          {!isLatestVersion && ((event) => clearCache(event))}
          <div id="snackbar"></div>
          <div id="failure-popup"></div>
          <div id="virtual-accounts-components"></div>
          <div id="view-ppi-card-details-component"></div>
          <div id="reports-component"></div>
          <div id="payments-components"></div>
          <div id="issue-new-card-components"></div>
          <div id="manage-beneficiary-components"></div>

          <LoginProvider>
            <Router history={history}>
              <Switch>
                <Route exact path="/">
                  <Redirect to="/dashboard" />
                </Route>
                <PrivateRoute path="/changelog" component={Changelog} />
                {process.env.REACT_APP_ENV_NAME !== "production" ? (
                  <PrivateRoute path="/login" component={Login} />
                ) : (
                  <PrivateRoute path="/login" component={Login2FA} />
                )}
                <PrivateRoute path="/signup" component={Signup} />
                <PrivateRoute
                  path="/forgot-password"
                  component={ForgotPassword}
                />
                <PrivateRoute path="/dashboard" component={Dashboard} />
              </Switch>
            </Router>
          </LoginProvider>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sentry.withProfiler(App);
