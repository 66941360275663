import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Components
import { Loader } from "rsuite";
import unMountPreviousComponent from "../../../../utilities/unMountPreviousComponent";
import TextFieldInput from "../../../UI/TextField/TextFieldInput";
import Tooltip from "../../../Shared/Tooltip/Tooltip";
import VirtualAccountsSuccessDialog from "../../../VirtualAccountsSuccessDialog/VirtualAccountsSuccessDialog";
import FailureDialog from "../../../Shared/FailureDialog/FailureDialog";
import TextFieldSelect from "../../../UI/TextField/TextFieldSelect";
// Formik Imoports
import { useFormik } from "formik";
import * as Yup from "yup";
// Axios Imports
import Axios from "axios";
// Regex Imports
import { REGEXP } from "../../../utilities/validators/inputValidators";
// Utils
import apiEndpointList from "../../../../config/core_banking/endpoint";
import { APIConfig } from "../../../../services/apiConfiguration";
import { capturePosthogEventCustom } from "../../../../utilities/postHogCustomUtils";
// Styles
import "./CreateVirtualAccountForm.scss";

function CreateVirtualAccountFormFormik() {
  const CancelToken = Axios.CancelToken;
  const source = CancelToken.source();

  // for holding Provider Bank
  const [providerApiData, setProviderApiData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [providerBankId, setProviderBankId] = useState(null);

  //  for holding Master Alias
  const [masterAlias, setMasterAlias] = useState("");
  const [aliasLoading, setAliasLoading] = useState(false);

  // Toggles for KYC
  const [verifyKyc, setVerifyKyc] = useState({
    kycVerifyToggle: false,
    decentroKycToggle: false,
  });

  const initialValues = {
    master_alias: "",
    customer_name: "",
    customer_id: "",
    customer_email: "",
    customer_pan: "",
    minimum_balance: "",
    transaction_limit: "",
    customer_mobile: "",
    customer_address: "",
    bank_provider: "",
  };

  const {
    nameRegex,
    customerIdRegex,
    emailRegex,
    panRegex,
    amountRegex,
    phoneNumberRegex,
    addressRegex,
  } = REGEXP;

  // Schema for validating form fields corresponding to regex imported.
  const validationSchema = Yup.object({
    customer_name: Yup.string()
      .matches(nameRegex)
      .required("Customer Name is required"),
    customer_id: Yup.string()
      .matches(customerIdRegex)
      .required("Customer ID is required"),
    customer_email: Yup.string()
      .matches(emailRegex)
      .required("Customer Email is required"),
    customer_pan: Yup.string()
      .matches(panRegex)
      .required("Customer PAN is required"),
    minimum_balance: Yup.number()
      .required()
      .min(0, "Must be at least 0")
      .test("is-min-balance", "Must be a valid number", (number) =>
        amountRegex.test(number)
      ),
    transaction_limit: Yup.number()
      .required()
      .positive()
      .test("transaction-limit", "Must be a valid number", (number) =>
        amountRegex.test(number)
      ),
    customer_mobile: Yup.string()
      .matches(phoneNumberRegex)
      .required("Customer Mobile  No. is required"),
    customer_address: Yup.string()
      .matches(addressRegex)
      .required("Customer Address is required"),
    bank_provider: Yup.mixed().required("Bank Provider is required"),
    master_alias: Yup.mixed().required("Master Alias is required"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,

    // This function will run when user will submit the form after it is validated.
    onSubmit: (values, action) => {
      const payloadData = {
        bank_codes: [values.bank_provider.value],
        name: values.customer_name,
        pan: values.customer_pan,
        email: values.customer_email,
        mobile: values.customer_mobile,
        address: values.customer_address,
        kyc_verified: verifyKyc.kycVerifyToggle,
        kyc_check_decentro: verifyKyc.decentroKycToggle,
        minimum_balance: +values.minimum_balance,
        transaction_limit: +values.transaction_limit,
        customer_id: values.customer_id,
        master_account_alias: values.master_alias.value,
      };
      if (
        process.env.REACT_APP_ENV_NAME === "staging" ||
        process.env.REACT_APP_ENV_NAME === "production"
      ) {
        if (window?.location?.origin && window?.location?.pathname) {
          try {
            capturePosthogEventCustom(
              window.location.origin,
              window.location.pathname,
              "Create Virtual Accounts CTA Submit",
              {
                additionalInfo: "User Submitted Virtual Accounts Form",
              }
            );
          } catch (error) {
            console.error("Error capturing Posthog event:", error);
          }
        } else {
          console.warn(
            "Unable to capture event: window.location properties are not fully defined"
          );
        }
      }
      createVirtualAccountApi(payloadData, action);
    },
  });

  // & Bank Provider Dropdown Details
  const cvaProvider = () => {
    setIsLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.PROVIDER_ACCOUNTS.baseUrl +
      apiEndpointList.PROVIDER_ACCOUNTS.endpoint,
      {},
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        setIsLoading(false);
        const options = response.data.map((item) => ({
          value: item.bank_code,
          label: item.bank_name,
          bank_id: item.bank_id,
        }));
        setProviderApiData(options);
      })
      .catch((error) => {
        console.log("error from CVAForm", error);
      });
  };

  // Fetching CVA Providers
  useEffect(() => {
    cvaProvider();
  }, []);

  // & Master Alias Dropdown Details
  const masterAliasProviderData = (provider) => {
    setAliasLoading(true);
    APIConfig.API_Client.post(
      apiEndpointList.MASTER_ALIAS_PROVIDER_ACCOUNTS.baseUrl +
      apiEndpointList.MASTER_ALIAS_PROVIDER_ACCOUNTS.endpoint,
      {
        provider_id: provider,
      },
      {
        cancelToken: source.token,
      }
    )
      .then((response) => {
        const options = response.data.map((item) => ({
          value: item.master_account_alias,
          label: item.master_account_alias,
        }));
        setAliasLoading(false);
        setMasterAlias(options);
      })
      .catch((error) => {
        console.log("error from CVAForm", error);
      });
  };

  // Fetching Master Alias Providers
  useEffect(() => {
    if (providerBankId !== null) {
      masterAliasProviderData(formik.values.bank_provider.bank_id);
    } else {
      setMasterAlias("");
      formik.setFieldValue("master_alias", "");
    }
  }, [providerBankId]);

  // & On submit CVA creation
  const createVirtualAccountApi = (payloadData, action) => {
    ReactDOM.render(
      <Loader size="xs" />,
      document.getElementById("user-config-loader")
    );

    APIConfig.API_Client.post(
      apiEndpointList.CREATE_VIRTUAL_ACCOUNT.baseUrl +
      apiEndpointList.CREATE_VIRTUAL_ACCOUNT.endpoint,
      payloadData,
      { cancelToken: source.token }
    )
      .then((response) => {
        action.resetForm();
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        unMountPreviousComponent("virtual-accounts-components");

        ReactDOM.render(
          <VirtualAccountsSuccessDialog data={response.data.data} />,
          document.getElementById("virtual-accounts-components")
        );
      })
      .catch((error) => {
        ReactDOM.unmountComponentAtNode(
          document.getElementById("user-config-loader")
        );

        unMountPreviousComponent("virtual-accounts-components");

        try {
          ReactDOM.render(
            <FailureDialog
              header={"Create Virtual Accounts"}
              msg={error.response.data.message}
            />,
            document.getElementById("failure-popup")
          );
        } catch (error) {
          ReactDOM.render(
            <FailureDialog
              header={"Create Virtual Accounts"}
              msg={"Something went wrong please try again later"}
            />,
            document.getElementById("failure-popup")
          );
        }
      })
      .finally(() => {
        // finally setting form submission false.
        action.setSubmitting(false);
      });
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="ui-form-details">
          <div className="ui-form-content">
            <div className="ui-form-inputs-section">
              <TextFieldSelect
                id="bank_provider"
                name="bank_provider"
                onChange={(selectedOption) => {
                  formik.setFieldValue("bank_provider", selectedOption);
                  if (selectedOption !== null) {
                    setProviderBankId(selectedOption.bank_id);
                  } else {
                    setProviderBankId(null);
                  }
                }}
                onBlur={() => formik.setFieldTouched("bank_provider", true)}
                value={formik.values.bank_provider}
                options={providerApiData}
                noOptionsMessage={() => "No Provider Exists"}
                label="Bank Provider"
                required={true}
                isLoading={isLoading}
                placeholder="Bank Provider"
                isformatOptionLabel={true}
                isToolTip={
                  "Bank provider on which Virtual Account has to be created"
                }
              />
              <TextFieldSelect
                id="master_alias"
                name="master_alias"
                onChange={(selectedOption) =>
                  formik.setFieldValue("master_alias", selectedOption)
                }
                onBlur={() => formik.setFieldTouched("master_alias", true)}
                value={formik.values.master_alias}
                options={masterAlias}
                noOptionsMessage={() => "No Master Alias Exists"}
                label="Master Alias"
                required={true}
                isLoading={aliasLoading}
                placeholder="Select Master Alias"
                isformatOptionLabel={true}
                isDisabled={!providerBankId || aliasLoading}
                isToolTip={"Master alias for the selected Bank Provider"}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="customer_name"
                name="customer_name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customer_name}
                touched={formik.touched.customer_name}
                error={formik.errors.customer_name}
                placeholder="Enter Customer's Name"
                label="Customer Name"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="customer_id"
                name="customer_id"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customer_id}
                touched={formik.touched.customer_id}
                error={formik.errors.customer_id}
                placeholder="Enter Customer's ID"
                label="Customer ID"
                required={true}
                disabled={false}
                isToolTip={"A unique ID for each customer"}
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="customer_email"
                name="customer_email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customer_email}
                touched={formik.touched.customer_email}
                error={formik.errors.customer_email}
                placeholder="Enter Customer's Email"
                label="Email"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="customer_pan"
                name="customer_pan"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customer_pan}
                touched={formik.touched.customer_pan}
                error={formik.errors.customer_pan}
                placeholder="Enter Customer's PAN"
                label="PAN"
                required={true}
                disabled={false}
                isToolTip={
                  "A valid PAN number of the virtual account holder. Required for sub-merchant onboarding"
                }
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="minimum_balance"
                name="minimum_balance"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.minimum_balance}
                touched={formik.touched.minimum_balance}
                error={formik.errors.minimum_balance}
                placeholder="Set Minimum Balance"
                label="Minimum Balance"
                required={true}
                disabled={false}
                isToolTip={
                  "The minimum amount that has to be maintained in the virtual account"
                }
              />
              <TextFieldInput
                id="transaction_limit"
                name="transaction_limit"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.transaction_limit}
                touched={formik.touched.transaction_limit}
                error={formik.errors.transaction_limit}
                placeholder="Set Transaction Limit"
                label="Transaction Limit"
                required={true}
                disabled={false}
                isToolTip={
                  "Maximum transaction amount for this virtual account. Ex - 10000"
                }
              />
            </div>

            <div className="ui-form-inputs-section">
              <TextFieldInput
                id="customer_mobile"
                name="customer_mobile"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customer_mobile}
                touched={formik.touched.customer_mobile}
                error={formik.errors.customer_mobile}
                placeholder="Enter Customer's Number"
                label="Customer Mobile No"
                required={true}
                disabled={false}
              />
              <TextFieldInput
                id="customer_address"
                name="customer_address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.customer_address}
                touched={formik.touched.customer_address}
                error={formik.errors.customer_address}
                placeholder="Enter Customer's Address"
                label="Address"
                required={true}
                disabled={false}
                isToolTip={"Address of the Virtual Account holder"}
              />
            </div>

            <div className="ui-form-inputs-section qr-inputs-section">
              <div className="cva-qr-toggle-container">
                <span
                  className="qr-toggle-container-label"
                  style={{ minWidth: "30%" }}
                >
                  KYC Verified
                  <Tooltip
                    requiredField={false}
                    text="True, if the KYC of the merchant has been verified by you"
                  >
                    <button className="info-icon">
                      <img src="/images/info-icon.svg" alt="information icon" />
                    </button>
                  </Tooltip>
                </span>
                <div className="toggle-section">
                  <span
                    className={
                      !verifyKyc.kycVerifyToggle ? "text-toggle" : undefined
                    }
                  >
                    No
                  </span>
                  <input
                    id="switch-one"
                    className="checkbox"
                    name="qrToggle"
                    value={verifyKyc.kycVerifyToggle}
                    checked={verifyKyc.kycVerifyToggle}
                    onChange={() =>
                      setVerifyKyc((prev) => ({
                        ...prev,
                        kycVerifyToggle: !prev.kycVerifyToggle,
                      }))
                    }
                    type="checkbox"
                  />
                  <label htmlFor="switch-one" className="toggle"></label>
                  <span
                    className={
                      verifyKyc.kycVerifyToggle ? "text-toggle" : undefined
                    }
                  >
                    Yes
                  </span>
                </div>
              </div>

              <div className="cva-qr-toggle-container">
                <span
                  className="qr-toggle-container-label"
                  style={{ minWidth: "30%" }}
                >
                  Decentro KYC
                  <Tooltip
                    requiredField={false}
                    text="True, if the KYC of the merchant is verified by the Decentro team"
                  >
                    <button className="info-icon">
                      <img src="/images/info-icon.svg" alt="information icon" />
                    </button>
                  </Tooltip>
                </span>
                <div className="toggle-section">
                  <span
                    className={
                      !verifyKyc.decentroKycToggle ? "text-toggle" : undefined
                    }
                  >
                    No
                  </span>
                  <input
                    id="switch-two"
                    className="checkbox"
                    name="decentroKycToggle"
                    value={verifyKyc.decentroKycToggle}
                    checked={verifyKyc.decentroKycToggle}
                    onChange={() =>
                      setVerifyKyc((prev) => ({
                        ...prev,
                        decentroKycToggle: !prev.decentroKycToggle,
                      }))
                    }
                    type="checkbox"
                  />
                  <label htmlFor="switch-two" className="toggle"></label>
                  <span
                    className={
                      verifyKyc.decentroKycToggle ? "text-toggle" : undefined
                    }
                  >
                    Yes
                  </span>
                </div>
              </div>
            </div>

            <div className="ui-button-container">
              <button
                type="submit"
                className={`submit-btn ${formik.isValid && formik.dirty && !formik.isSubmitting
                  ? "active"
                  : ""
                  }`}
                disabled={!formik.isValid || formik.isSubmitting}
              >
                {formik.isSubmitting ? "Loading..." : "Submit"}
                <span
                  id="user-config-loader"
                  style={{ display: "flex" }}
                ></span>
              </button>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}

export default CreateVirtualAccountFormFormik;
