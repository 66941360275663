import React from "react";
import ReactDOM from "react-dom";
import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Axios from "axios";
// STYLES
import "./UpdateModal.scss";
// UTILS
import { data } from "jquery";
import { APIConfig } from "../../../services/apiConfiguration";
import apiEndpointList from "../../../config/core_banking/endpoint";
import { REGEXP } from "../../utilities/validators/inputValidators";
// FORMIK
import { useFormik } from "formik";
import * as Yup from "yup";
// COMPONENTS
import TextFieldInput from "../../UI/TextField/TextFieldInput";
import TextFieldSelect from "../../UI/TextField/TextFieldSelect";
import FailureForCTA from "../../Shared/FailureForCTA/FailureForCTA";
import CommonSuccessDialog from "../../CommonSuccessDialog/CommonSuccessDialog";


function UpdateModal({ accountDetails }) {
    return (
        <div>
            <ModalComponent {...(data = data)} accountDetails={accountDetails} />
        </div>
    );
}

// Modal unmount function
const closeTableDetailModal = () => {
    ReactDOM.unmountComponentAtNode(
        document.getElementById("virtual-accounts-components")
    );
};

function ModalComponent({ accountDetails }) {
    const CancelToken = Axios.CancelToken;
    const source = CancelToken.source();
    const StatusOptions = [
        {
            value: "active",
            label: "Active",
        },
        {
            value: "inactive",
            label: "Inactive",
        }
    ]

    const initialValues = {
        name: accountDetails.account_name,
        email: accountDetails.email_address,
        transaction_limit: accountDetails.transaction_limit === "-" ? 0 : accountDetails.transaction_limit,
        minimum_balance: accountDetails.minimum_balance === "-" ? 0 : accountDetails.minimum_balance,
        account_status: {
            value: accountDetails.account_status,
            label: accountDetails.account_status,
        }

    };

    const { nameRegex, emailRegex, amountRegex } = REGEXP;
    // Schema for validating form fields corresponding to regex imported.
    const validationSchema = Yup.object({
        name: Yup.string().matches(nameRegex).required("Name is required"),
        email: Yup.string()
            .matches(emailRegex)
            .required("Email is required"),
        transaction_limit: Yup.string()
            .matches(amountRegex)
            .required("Transaction Limit is required"),
        minimum_balance: Yup.string()
            .matches(amountRegex)
            .required("Transaction Limit is required"),
    });

    const formik = useFormik({
        initialValues,
        validationSchema,
        validateOnChange: true,
        validateOnBlur: true,

        // This function will run when user will submit the form after it is validated.
        onSubmit: (values, action) => {
            const payload = {
                ...values,
                "action": "update",
                "account_status": values.account_status.value.toLowerCase(),
            }
            updateVA(payload, action);
        },
    });
    const updateVA = (payloadData, action) => {
        action.setSubmitting(true);
        APIConfig.API_Client.defaults.headers.put["provider_code"] = accountDetails.provider_code;
        APIConfig.API_Client.put(
            `${apiEndpointList.CREATE_VIRTUAL_ACCOUNT.baseUrl + apiEndpointList.CREATE_VIRTUAL_ACCOUNT.endpoint}/${accountDetails?.account_number}`,
            payloadData,
            { cancelToken: source.token }
        )
            .then((response) => {
                ReactDOM.unmountComponentAtNode(
                    document.getElementById("user-config-loader")
                );
                const dynamicHeading = {
                    message: response.data.data.message,
                    dialogHeading: "Virtual Account Update Successful"
                }
                const successDataToShow = {
                    status: response.data.data.status,
                    accountNumber: response.data.data.accountNumber,
                    ifsc: response.data.data.ifsc,
                    allowedMethods: response.data.data.allowedMethods,
                    transactionLimit: response.data.data.transactionLimit,
                    minimumBalance: response.data.data.minimumBalance,
                }
                closeTableDetailModal()
                ReactDOM.render(
                    <CommonSuccessDialog data={successDataToShow} dynamicHeading={dynamicHeading} />,
                    document.getElementById("payments-components")
                );
            })
            .catch((error) => {
                ReactDOM.unmountComponentAtNode(
                    document.getElementById("user-config-loader")
                );
                closeTableDetailModal()
                ReactDOM.render(
                    <FailureForCTA
                        header={"Virtual Accounts"}
                        msg={error?.response?.data?.message}
                    />,
                    document.getElementById("failure-popup")
                );
            })
            .finally(() => {
                // finally setting form submission false.
                action.setSubmitting(false);
            });
    };

    return (
        <React.Fragment>
            <Dialog
                className="va-details"
                PaperProps={{ style: { borderRadius: 10, width: "800px" } }}
                open={true}
                aria-labelledby="form-dialog-title"
            >
                <div className="close-popup">
                    <CloseIcon onClick={closeTableDetailModal} />
                </div>

                <DialogContent>
                    <div className="va-details-heading">
                        <p>Update VA&nbsp;
                            <span>
                                {accountDetails.account_number}
                            </span></p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="ui-form-details">
                            <div className="ui-form-content">
                                <div className="ui-form-inputs-section">
                                    <TextFieldInput
                                        id="name"
                                        name="name"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.name}
                                        touched={formik.touched.name}
                                        error={formik.errors.name}
                                        placeholder="Enter Name"
                                        label="Name"
                                        required={true}
                                        disabled={false}
                                    />

                                    <TextFieldInput
                                        id="email"
                                        name="email"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.email}
                                        touched={formik.touched.email}
                                        error={formik.errors.email}
                                        placeholder="Enter Email"
                                        label="Email"
                                        required={true}
                                        disabled={true}
                                    />
                                </div>

                                <div className="ui-form-inputs-section">
                                    <TextFieldInput
                                        id="transaction_limit"
                                        name="transaction_limit"
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue("transaction_limit", selectedOption.target.value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.transaction_limit}
                                        touched={formik.touched.transaction_limit}
                                        error={formik.errors.transaction_limit}
                                        placeholder="Enter Transaction Limit"
                                        label="Transaction Limit"
                                        required={true}
                                        disabled={false}
                                    />
                                    <TextFieldInput
                                        id="minimum_balance"
                                        name="minimum_balance"
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue("minimum_balance", selectedOption.target.value);
                                        }}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.minimum_balance}
                                        touched={formik.touched.minimum_balance}
                                        error={formik.errors.minimum_balance}
                                        placeholder="Enter Minimum Balance"
                                        label="Minimum Balance"
                                        required={true}
                                        disabled={false}
                                    />
                                </div>

                                <div className="ui-form-inputs-section">

                                    <TextFieldSelect
                                        id="account_status"
                                        name="account_status"
                                        onChange={(selectedOption) => {
                                            formik.setFieldValue("account_status", selectedOption);
                                        }}
                                        onBlur={() => formik.setFieldTouched("account_status", true)}
                                        value={formik.values.account_status}
                                        options={StatusOptions}
                                        noOptionsMessage={() => "No Status Exists"}
                                        label="Account Status"
                                        required={true}
                                        placeholder="Account Status"
                                        isformatOptionLabel={true}
                                        isDisabled={true}
                                    />
                                </div>

                                <div className="ui-button-container">
                                    <button
                                        type="submit"
                                        className={`submit-btn ${formik.isValid && formik.dirty && !formik.isSubmitting
                                            ? "active"
                                            : ""
                                            }`}
                                        disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
                                    >
                                        {formik.isSubmitting ? "Loading..." : "Submit"}
                                        <span
                                            id="user-config-loader"
                                            style={{ display: "flex" }}
                                        ></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </form>
                </DialogContent>
            </Dialog>
        </React.Fragment >
    );
}

export default UpdateModal;