export const loadScriptWithPriority = (scriptContent, priority) => {
    const delays = {
        high: 1000,
        medium: 2000,
        low: 3000,
    };

    const delay = delays[priority];

    if (typeof window !== "undefined") {
        window.requestIdleCallback
            ? window.requestIdleCallback(() => {
                setTimeout(() => {
                    new Function(scriptContent)();
                }, delay);
            })
            : setTimeout(() => {
                new Function(scriptContent)();
            }, delay);
    }
};