import ReactDOM from 'react-dom';
import UpdateModal from "../../components/VirtualAccountDataTable/UpdateModal/UpdateModal";

const UpdateCell = (accountDetails) => {
    const handleClick = () => {
        ReactDOM.render(
            <UpdateModal accountDetails={accountDetails} />,
            document.getElementById("virtual-accounts-components")
        );
    }
    const lineItem = <img className="download-report-icon" src="/images/update.svg" alt="update-icon" />
    return <div className="reports-download-cta" onClick={handleClick}>{lineItem}</div>;
}

export default UpdateCell