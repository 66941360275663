import React, { useContext } from "react";
import ReactDOM from "react-dom";
// Components
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
// Utils
import CloseIcon from "@material-ui/icons/Close";
import { Dialog, DialogContent } from "@material-ui/core";
// Styles
import "./CommonSuccessDialog.scss";

function CommonSuccessDialog(props) {
    const closeSuccessDialog = () => {
        ReactDOM.unmountComponentAtNode(
            document.getElementById("payments-components")
        );
        window.location.reload()
    };

    const formatValue = (value) => {
        if (value === null || value === undefined) return "-";
        if (Array.isArray(value)) return value.join(", ");
        if (typeof value === 'boolean') return value ? 'Yes' : 'No';
        return String(value);
    };

    const formatKey = (key) => {
        return key
            .replace(/([A-Z])/g, ' $1')  // Insert space before capital letters
            .replace(/^./, str => str.toUpperCase())  // Capitalize first letter
            .trim();
    };

    const renderTableRows = () => {
        const { data } = props;

        return Object.entries(data).map(([key, value]) => {
            // Skip if the key is something we don't want to display (like internal flags)
            if (['$typeof', '_owner'].includes(key)) return null;

            return (
                <tr key={key}>
                    <td className="accounts-heading">{formatKey(key)}</td>
                    <td className="accounts-details title-case">
                        {formatValue(value)}
                    </td>
                </tr>
            );
        }).filter(Boolean);  // Remove any null entries
    };

    return (
        <React.Fragment>
            <Dialog
                className="success-dialog-container"
                PaperProps={{ style: { borderRadius: 10 } }}
                open={true}
                aria-labelledby="form-dialog-title"
            >
                <div className="close-success-popup">
                    <CloseIcon onClick={closeSuccessDialog} />
                </div>

                <DialogContent>
                    <div className="dialog-heading">
                        <p>{props.dynamicHeading.dialogHeading}</p>
                    </div>
                    <div className="dialog-content-container">
                        <div className="success-message">
                            <img src="/images/onsuccess-tick.svg" alt="success-icon" />
                            <h3>{props.dynamicHeading.message}</h3>
                        </div>
                        <div className="success-account-details">
                            <table>
                                <tbody>
                                    {renderTableRows()}
                                </tbody>
                            </table>
                        </div>
                        <div className="success-btn-container">
                            <button onClick={closeSuccessDialog} className="success-btn-done">
                                Done
                            </button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </React.Fragment>
    );
}

export default CommonSuccessDialog;
